/*
 * used Google Fonts to find a font, and used the below site to get all the variants
 * https://google-webfonts-helper.herokuapp.com/fonts/lato?subsets=latin
 *
 * references:
 * https://stackoverflow.com/questions/41676054/how-to-add-fonts-to-create-react-app-based-projects
 * https://fonts.google.com/specimen/Lato
 */

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/webfonts/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
    url('/webfonts/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/webfonts/lato-v16-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/webfonts/lato-v16-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('/webfonts/lato-v16-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/webfonts/lato-v16-latin-300.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/webfonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
    url('/webfonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/webfonts/lato-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/webfonts/lato-v16-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/webfonts/lato-v16-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/webfonts/lato-v16-latin-regular.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/webfonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
    url('/webfonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/webfonts/lato-v16-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/webfonts/lato-v16-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('/webfonts/lato-v16-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/webfonts/lato-v16-latin-700.svg#Lato')
      format('svg'); /* Legacy iOS */
}
/* BEGIN CSS RESET */

/* http://meyerweb.com/eric/tools/css/reset/
   v3.0 | 20180413
   License: none (public domain)
*/

/* prettier-ignore */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
}

/* Removed sup and sub (super/subscript) from the above */
/* prettier-ignore */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  vertical-align: baseline;
}

/* `padding: 0`
      Added by Max. For some reason inputs have default 1px padding, at least in Chrome

  `min-width: 0`
      Added by Monroe. Browsers also set a min-width on inputs,
      which messes with things like flex-grow: https://stackoverflow.com/a/42421490/2544629

  `margin: 0`
      Added by Monroe for deals, chrome had some margin on inputs like checkboxes/toggles
 */
input {
  padding: 0;
  min-width: 0;
  margin: 0;
}

/* HTML5 display-role reset for older browsers */
/* prettier-ignore */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
/* prettier-ignore */
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* END CSS RESET */

/* apply a natural box layout model to all elements,
   but allowing components (and their children) to change it*/
html {
  box-sizing: border-box;
  font-weight: 400;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: var(--ds-black);
  font-size: 16px;
}
/* prettier-ignore */
*, *:before, *:after {
  box-sizing: inherit;
  font-weight: inherit;
  font-family: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: inherit;
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
  padding: unset;
}
button:focus {
  outline: 1px dotted;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
a {
  color: var(--ds-blue);
}

/* not all of this may be necessary, but it seems like we need more CSS resetting here to get our font everywhere */
button,
input,
select,
ul,
li,
body {
  font-family: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* couldn't get cssVariables.js to work so this will work for now :( */
:root {
  --ds-blue: #4165ff;
  --ds-blue-lighter: #607aff;
  --ds-blue-light: #5466f9;
  --ds-blue-light-hover: #4b5fea;
  --ds-blue-dark: #3645bb;
  --ds-blue-dark-hover: #3041a8;
  --ds-white: #ffffff;
  --ds-off-white: #f9f9fb;
  --ds-off-white-dark: #f2f2f7;
  --ds-pearl-grey: #efeffd;
  --ds-pearl-grey-medium: #e4e5fc;
  --ds-pearl-grey-dark: #d8d8e9;
  --ds-pearl-grey-darker: #cacadd;
  --ds-pearl-grey-darkest: #9d9db0;
  --ds-grey: #71718e;
  --ds-grey-medium: #565674;
  --ds-grey-dark: #41415a;
  --ds-black: #1f1f30;
  --ds-pearl-grey-hover: #e4e5fc;
  --ds-off-white-hover: #efeffd;
  --ds-green-money-in: #008375;
  --ds-mauve: #d9beff;
  --ds-highlight: #eaf3fe;
  --ds-highlight-dark: #cce3fc;
  --ds-highlight-darker: #aac7f8;
  --ds-green-light: #c0ffee;
  --ds-green: #0ad19f;
  --ds-green-dark: #004f49;
  --ds-red-light: #fbe5f0;
  --ds-red-dark: #c5046c;
  --ds-red: #d9177e;

  /* https://encycolorpedia.com/224132 */
  --ds-poker-green: #35654d;
  --ds-poker-green-darker: #1b3428;
  --ds-poker-green-lighter: #1e4532;
  --ds-poker-green-darkest: rgb(26, 43, 34);

  /* just copied this from mw for now for <SettingsCircle/>, may or may not need it. */
  --popup-menu-z-index: 4;
}

.light {
  --ds-background: white;
  --ds-background-transparent: white;
  --ds-background-rgb: 255, 255, 255;
  --ds-black: #1f1f30;
  --ds-white: #ffffff;
  --shadow-white: rgba(228, 228, 234, 0.22);
  --svg-black: invert(9%) sepia(21%) saturate(1306%) hue-rotate(201deg)
    brightness(89%) contrast(89%);
  --ds-highlight: #eaf3fe;
  --ds-off-white: #f9f9fd;
  --ds-off-white-dark: #f2f2f7;
  --ds-pearl-grey: #efeffd;
  --ds-grey-medium: #565674;
  --ds-grey: #71718e;
  --ds-grey-dark: #41415a;
  --ds-red: #d9177e;
  --ds-red-light: #fbe5f0;
  --ds-red-dark: #c5046c;
  --ds-selected-tab: #eaf4fe;
  --ds-blue-light: #5466f9;
  --ds-blue-light-hover: #4b5fea;

  --ds-game-text: #ffffff;
  --ds-poker-green-lighter: #1e4532;
  --ds-poker-green-darker: #1b3428;
}

.dark {
  --ds-background: #181818;
  --ds-background-rgb: 24, 24, 24;
  --ds-black: #eaeaf1;
  --ds-white: #cccccc; /* light color to preserve the GameBoard light-on-dark look */
  --shadow-white: rgba(0, 0, 6, 0.22);
  --svg-black: invert(100%) sepia(2%) saturate(7462%) hue-rotate(33deg)
    brightness(103%) contrast(109%);
  --ds-highlight: #2a333e;
  --ds-off-white: #32323e;
  --ds-off-white-dark: #222227;
  --ds-pearl-grey: #4f4f5d;
  --ds-grey-medium: #9696b4;
  --ds-grey-dark: #c1c1de;
  --ds-grey: #81819a;
  --ds-red: #d9177e;
  --ds-red-dark: #f5349c;
  --ds-red-light: #16000b;
  --ds-selected-tab: #233546;
  --ds-blue-light: #364199;
  --ds-blue-light-hover: #263189;

  --ds-game-text: #cccccc;
  --ds-poker-green-lighter: #143424;
  --ds-poker-green-darker: #0b1812;
}

body {
  background-color: var(--ds-background);
}
