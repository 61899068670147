.waitingRoomWrapper {
  padding: 40px;
}

.playerList {
  margin: 20px 10px 40px;
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: auto;
  grid-auto-flow: row;
}

.playerRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & img {
    margin-right: 8px;
  }

  position: relative;

  & .kickPlayerIcon,
  & .hostIcon {
    position: absolute;
    left: -24px;
  }

  & .kickPlayerIcon {
    cursor: pointer;
    transform: rotate(180deg);
  }

  & .hostIcon {
    color: #d4ad00;
  }
}

.button {
  margin-top: 30px;
}
